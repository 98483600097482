import React, { useEffect, useRef, useState } from "react";
import Heading from "../../component/Heading";
import AlertBox from "../../component/AlertBox";
import Loading from "../../component/Loading";
import { axiosInstance } from "../../constant";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import ButtonCompo from "../../component/ButtonCompo";
import { Link } from "react-router-dom";
import { IoMdCloudDownload } from "react-icons/io";
import { IoMdRefresh } from "react-icons/io";
import { DatePicker } from "@mui/x-date-pickers";
import DialogBox from "../../component/DialogBox";
import { FaCheckCircle } from "react-icons/fa";

function STProductsReport() {
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(undefined);
  const [endaDate, setEndDate] = useState(undefined);
  const [reportLink, setReportLink] = useState("");
  const dialogBoxRef = useRef(null);

  // get orders
  async function getOrders() {
    try {
      setLoading(true);
      const data = {
        startDate: startDate,
        endDate: endaDate,
        productName: search,
      };
      const res = await axiosInstance.post(
        `/api/v1/report/get/product/report`,
        data
      );
      const rawData = res.data?.data;
      console.log(res.data?.data);
      setOrderData(rawData);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // generate product report
  async function generateDailyReport() {
    try {
      setLoading(true);
      const data = {
        data: orderData,
      };
      const res = await axiosInstance.post(
        `/api/v1/report/generate/products`,
        data
      );
      setReportLink(res.data?.data?.data);
      if (dialogBoxRef.current) {
        dialogBoxRef.current.handleOpen();
      }
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getOrders();
  }, [reload]);

  function onSearch() {
    setReload((prev) => !prev);
  }

  function onReload() {
    setReload((prev) => !prev);
    setKeyword("");
    setSearch("");
    setStartDate(undefined);
    setEndDate(undefined);
  }

  return (
    <>
      {loading && <Loading />}
      <div className="w-full flex justify-center items-center">
        {success !== undefined && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        <DialogBox
          ref={dialogBoxRef}
          actionButton={
            <Link to={reportLink} target="_blank"><ButtonCompo>View Report</ButtonCompo></Link>
          }
        >
          <div className="flex flex-col justify-center items-center gap-3">
            <FaCheckCircle size={40} className="text-green-500" />
            <p className="text-slate-500">
              Report has been generated successfully
            </p>
          </div>
        </DialogBox>
        <div className="w-[90vw]">
          <Heading>Products Reports</Heading>
          <div className="w-full flex justify-between gap-3 mt-[2rem]">
            <div className="flex items-center gap-5">
              <DatePicker
                label="From"
                onChange={(value) => setStartDate(value)}
              />
              <DatePicker label="To" onChange={(value) => setEndDate(value)} />
            </div>
            <div className="flex items-center gap-5">
              <ButtonCompo
                variant={"outline"}
                startIcon={<IoMdRefresh />}
                onClick={onReload}
              >
                Refresh
              </ButtonCompo>
              <section className="flex gap-3">
                <TextField
                  size="small"
                  placeholder="Search Product Name"
                  onChange={(event) => setSearch(event?.target?.value)}
                />
                <ButtonCompo variant={"primary"} onClick={onSearch}>
                  Search
                </ButtonCompo>
              </section>
            </div>
          </div>
          <div className="w-full flex justify-end gap-3 mt-[1rem]">
            <ButtonCompo
              variant={"primary"}
              startIcon={<IoMdCloudDownload />}
              onClick={generateDailyReport}
            >
              Generate Report
            </ButtonCompo>
          </div>
          <div className="mt-5">
            <Table>
              <TableHead>
                <TableRow className="bg-[#FF8000]">
                  <TableCell className="!text-white !font-semibold !text-center">
                    Serial No.
                  </TableCell>
                  <TableCell className="!text-white !font-semibold !text-left">
                    Product
                  </TableCell>
                  <TableCell className="!text-white !font-semibold !text-center">
                    MRP
                  </TableCell>
                  <TableCell className="!text-white !font-semibold !text-center">
                    Selling Price
                  </TableCell>
                  <TableCell className="!text-white !font-semibold !text-center">
                    Order Delivered
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderData?.map((order, i) => (
                  <TableRow className="bg-white">
                    <TableCell className="!text-slate-800 !text-center">
                      {i + 1}
                    </TableCell>
                    <TableCell className="!text-slate-800 !text-left">
                      <span className="font-semibold">
                        {order?.productTitle}
                      </span>
                    </TableCell>
                    <TableCell className="!text-slate-800 !text-center">
                      ₹{order?.mrp}
                    </TableCell>
                    <TableCell className="!text-slate-800 !text-center">
                      ₹{order?.sellingPrice}
                    </TableCell>
                    <TableCell className="!text-slate-800 !text-center">
                      {order?.products?.length}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
}

export default STProductsReport;
