import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FaMoneyBill1 } from "react-icons/fa6";
import { MdContactMail, MdDashboard } from "react-icons/md";
import { AiFillProduct } from "react-icons/ai";
import { BsFillInboxesFill } from "react-icons/bs";
import { RiCoupon3Fill } from "react-icons/ri";
import { FaGift } from "react-icons/fa6";
import { FaImage } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { RiCustomerService2Fill } from "react-icons/ri";
import logo from "../../assets/companyLogo.png";
import { axiosInstance } from "../../constant";

function STDashboard() {
  // navigate to home if superadmin is not logged
  const isStaffAuthenticated = useSelector(
    (state) => state.staffSlice?.isStaffAuthenticated
  );
  const staffData = useSelector((state) => state.staffSlice?.staffData);

  const navigate = useNavigate();
  useEffect(() => {
    if (isStaffAuthenticated !== undefined && isStaffAuthenticated === false) {
      navigate("/");
    }
  }, [isStaffAuthenticated]);

  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0)

  // get dashboard data
  async function getData() {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/api/v1/payment/get/new/orders/count`);
      console.log(res.data?.statusCode?.data);
      if(!isNaN(res.data?.statusCode?.data)) {
        setTotalCount(res.data?.statusCode?.data)
      }
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() =>{
    getData()
  },[])

  const dataArray = [
    {
      title: "Products",
      icon: (
        <AiFillProduct
          size={"70px"}
          className="text-orange-400 hover:text-orange-600"
        />
      ),
      link: "/staff/products",
    },
    {
      title: "Add Product",
      icon: (
        <AiFillProduct
          size={"70px"}
          className="text-orange-400 hover:text-orange-600"
        />
      ),
      link: "/staff/product/add",
    },
    {
      title: "Catagories",
      icon: (
        <BsFillInboxesFill
          size={"70px"}
          className="text-orange-400 hover:text-orange-600"
        />
      ),
      link: "/staff/catagories",
    },
    {
      title: "New Orders",
      icon: (
        <FaGift
          size={"70px"}
          className="text-orange-400 hover:text-orange-600"
        />
      ),
      link: "/staff/orders/new",
    },
    {
      title: "Order History",
      icon: (
        <FaGift
          size={"70px"}
          className="text-orange-400 hover:text-orange-600"
        />
      ),
      link: "/staff/orders",
    },
    {
      title: "Coupons",
      icon: (
        <RiCoupon3Fill
          size={"70px"}
          className="text-orange-400 hover:text-orange-600"
        />
      ),
      link: "/staff/coupons",
    },
    {
      title: "Charges",
      icon: (
        <FaMoneyBill1
          size={"70px"}
          className="text-orange-400 hover:text-orange-600"
        />
      ),
      link: "/staff/charges",
    },
    {
      title: "Users",
      icon: (
        <FaUsers
          size={"70px"}
          className="text-orange-400 hover:text-orange-600"
        />
      ),
      link: "/staff/users",
    },
    {
      title: "Inquiry",
      icon: (
        <MdContactMail
          size={"70px"}
          className="text-orange-400 hover:text-orange-600"
        />
      ),
      link: "/staff/contact",
    },
    {
      title: "IT Support",
      icon: (
        <RiCustomerService2Fill
          size={"70px"}
          className="text-orange-400 hover:text-orange-600"
        />
      ),
      link: "https://wa.me/9148054805/?text=Hi%20AppNest%0AWe%20are%20from%20NBS%0AWe%20are%20facing%20issue%20on%20website",
    },
  ];

  if (loading) {
    return (
      <div className="w-full h-[100vh] flex justify-center items-center">
        <img src={logo} className="w-[10rem]" alt="" />
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <h1 className="mt-[5rem] heading_text text-[35px] font-bold">
        Staff Dashboard
      </h1>
      <div className="w-[90vw] xl:w-[70vw] mt-[3rem]">
        <div className="grid grid-cols-5 gap-[7rem] place-items-center text-center">
          {dataArray?.map((item, i) => (
            <Link to={item?.link}>
              {item?.title === "New Orders" ? (
                <div className="relative">
                  <section className="flex justify-center items-center flex-col gap-3">
                    <span>{item?.icon}</span>
                    <p className="font-semibold text-slate-700">
                      {item?.title}
                    </p>
                  </section>
                  <p className="bg-red-500 w-[30px] h-[30px] flex justify-center items-center text-white font-bold rounded-full absolute top-0 right-0">
                    {totalCount}
                  </p>
                </div>
              ) : (
                <section className="flex justify-center items-center flex-col gap-3">
                  <span>{item?.icon}</span>
                  <p className="font-semibold text-slate-700">{item?.title}</p>
                </section>
              )}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default STDashboard;
